"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountingIntegrationActionEnum = void 0;
var AccountingIntegrationActionEnum;
(function (AccountingIntegrationActionEnum) {
  AccountingIntegrationActionEnum["ManageTenants"] = "manage:tenants";
  AccountingIntegrationActionEnum["ViewTenants"] = "view:tenants";
  AccountingIntegrationActionEnum["UpdateMapping"] = "update:mapping";
  /**
   * @deprecated
   */
  // SyncInvoices = 'sync:invoices',
})(AccountingIntegrationActionEnum = exports.AccountingIntegrationActionEnum || (exports.AccountingIntegrationActionEnum = {}));
