import { Language } from '@supy.api/dictionaries';

import { LocalizedData } from '@supy/common';

export function getLocalizedName(name?: LocalizedData | null): string {
  const htmlTag = globalThis.document?.querySelector('html');

  if (htmlTag) {
    const userLanguage = htmlTag.dataset.userLanguage as Language;
    const alternateLanguage = htmlTag.dataset.alternateLanguage as Language;

    return alternateLanguage &&
      userLanguage &&
      alternateLanguage === userLanguage &&
      alternateLanguage !== Language.English
      ? (name?.ar ?? name?.en ?? '')
      : (name?.en ?? name?.ar ?? '');
  }

  return name?.en ?? name?.ar ?? '';
}
