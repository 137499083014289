import { switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { AsiaTimezone, Currency } from '@supy.api/dictionaries';

import { DropdownTreeNode } from '@supy/components';

import {
  AccountingCategory,
  PushToInventoryMethod,
  RecipeCategory,
  SalesType,
  SalesTypeStateModel,
  Settings,
  WastageType,
} from '../../core';
import { SettingsService } from '../../services';
import {
  AddAccountingCategory,
  AddRecipeCategory,
  AddWastageType,
  CreateSalesType,
  DeleteSalesType,
  GetSettings,
  MakeDefaultSalesType,
  RemoveAccountingCategory,
  RemoveRecipeCategory,
  RemoveWastageType,
  SaveSettings,
  UpdateAccountingCategory,
  UpdateRecipeCategory,
  UpdateSalesType,
  UpdateWastageType,
} from '../actions';

export type SettingsStateModel = Settings;
export type RecipeCategoryTree = DropdownTreeNode<string> & RecipeCategory;

export const SETTINGS_STATE_TOKEN = new StateToken<SettingsStateModel>('settings');

@State<SettingsStateModel>({
  name: SETTINGS_STATE_TOKEN,
})
@Injectable()
export class SettingsState {
  private static readonly recipeCategoriesCacheMap: { [id: string]: string } = {};

  @Selector()
  static generalSettings({ generalSettings }: SettingsStateModel) {
    return generalSettings;
  }

  @Selector()
  static wastageTypes({ wastageTypes }: SettingsStateModel) {
    return wastageTypes;
  }

  @Selector()
  static costingMethodSettings({ costingMethodSettings }: SettingsStateModel) {
    return costingMethodSettings;
  }

  @Selector()
  static pushToInventorySettings({ pushToInventorySettings }: SettingsStateModel) {
    return pushToInventorySettings;
  }

  @Selector()
  static automaticInventoryPush(state: SettingsStateModel): boolean {
    return state.pushToInventorySettings?.method === PushToInventoryMethod.Auto;
  }

  @Selector()
  static preventBackdatedUpdates({ pushToInventorySettings }: SettingsStateModel) {
    return pushToInventorySettings?.preventBackdatedUpdates ?? false;
  }

  @Selector()
  static allowCkInvoice({ pushToInventorySettings }: SettingsStateModel) {
    return pushToInventorySettings?.allowCkInvoice ?? false;
  }

  @Selector()
  static recipeCategories({ recipeCategories = [] }: SettingsStateModel) {
    return recipeCategories;
  }

  @Selector()
  static nonEmptyRecipeCategories({ recipeCategories = [] }: SettingsStateModel) {
    return recipeCategories.filter(({ children }) => children?.length);
  }

  @Selector()
  static mandatoryAccountingCategoryOnItem({ mandatoryAccountingCategoryOnItem }: SettingsStateModel) {
    return mandatoryAccountingCategoryOnItem ?? false;
  }

  @Selector()
  static recipeCategoriesTree(state: SettingsStateModel) {
    return this.mapRecipeCategoriesTree(state.recipeCategories);
  }

  @Selector()
  static accountingCategories({ accountingCategories }: SettingsStateModel) {
    return accountingCategories;
  }

  @Selector()
  static currency({ generalSettings }: SettingsStateModel) {
    return generalSettings?.country?.currency ?? Currency.UnitedArabEmiratesDirham;
  }

  @Selector()
  static utcOffset({ generalSettings }: SettingsStateModel) {
    return generalSettings?.country?.utcOffset ?? 0;
  }

  @Selector()
  static ianaTimeZone({ generalSettings }: SettingsStateModel) {
    return generalSettings?.country?.ianaTimeZone ?? AsiaTimezone.Dubai;
  }

  @Selector()
  static currencyPrecision({ generalSettings }: SettingsStateModel) {
    return generalSettings?.country?.currencyPrecision ?? 2;
  }

  @Selector()
  static manageChannelItemsPerLocation({ manageChannelItemsPerLocation }: SettingsStateModel) {
    return manageChannelItemsPerLocation ?? false;
  }

  static recipeCategoryName(id: string) {
    return createSelector([SettingsState], (state: SettingsStateModel) => {
      if (this.recipeCategoriesCacheMap[id]) {
        return this.recipeCategoriesCacheMap[id];
      }

      this.recipeCategoriesCacheMap[id] = this.findRecipeCategoryName(state.recipeCategories, id);

      return this.recipeCategoriesCacheMap[id];
    });
  }

  private static mapRecipeCategoriesTree(categories: RecipeCategory[]): RecipeCategoryTree[] {
    return (categories ?? []).map(category => ({
      ...category,
      unselectable: !!category.children?.length,
      children: this.mapRecipeCategoriesTree(category.children ?? []),
    }));
  }

  private static findRecipeCategoryName(categories: RecipeCategory[], id: string): string {
    return categories?.reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      if (id && curr.id && curr.id === id) {
        return curr.name;
      }

      return this.findRecipeCategoryName(curr.children ?? [], id);
    }, '');
  }

  @Selector()
  static salesTypes({ salesTypes }: SettingsStateModel): SalesType[] {
    return salesTypes;
  }

  readonly #settingsService: SettingsService = inject(SettingsService);

  @Action(GetSettings)
  getSettings({ setState }: StateContext<SettingsStateModel>, { payload: { retailerId } }: GetSettings) {
    return this.#settingsService
      .get(retailerId)
      .pipe(tap(data => setState(Settings.deserialize({ ...data, id: retailerId }))));
  }

  @Action(SaveSettings)
  saveSettings({ getState }: StateContext<SettingsStateModel>, { payload }: SaveSettings) {
    return this.#settingsService.update(
      Settings.serialize({
        ...getState(),
        ...payload,
      }),
    );
  }

  @Action(AddWastageType)
  addWastageType({ setState }: StateContext<SettingsStateModel>, { payload }: AddWastageType) {
    return this.#settingsService
      .createWastageType({
        ...payload,
      })
      .pipe(
        tap(response =>
          setState(
            patch({
              wastageTypes: append([WastageType.deserialize(response)]),
            }),
          ),
        ),
      );
  }

  @Action(UpdateWastageType)
  updateWastageType({ setState }: StateContext<SettingsStateModel>, { payload }: UpdateWastageType) {
    return this.#settingsService.updateWastageType(payload).pipe(
      tap(() => {
        setState(
          patch({
            wastageTypes: updateItem<WastageType>(wastageType => wastageType?.id === payload.id, payload),
          }),
        );
      }),
    );
  }

  @Action(RemoveWastageType)
  removeWastageType({ setState }: StateContext<SettingsStateModel>, { payload }: RemoveWastageType) {
    return this.#settingsService.deleteWastageType(payload.id).pipe(
      tap(() =>
        setState(
          patch({
            wastageTypes: removeItem<WastageType>(wastageType => wastageType?.id === payload.id),
          }),
        ),
      ),
    );
  }

  @Action(AddRecipeCategory)
  addRecipeCategory(ctx: StateContext<SettingsStateModel>, { payload }: AddRecipeCategory) {
    return this.#settingsService
      .createRecipeCategory(payload)
      .pipe(switchMap(() => this.getSettings(ctx, { payload: { retailerId: payload.retailer.id } })));
  }

  @Action(UpdateRecipeCategory)
  updateRecipeCategory(ctx: StateContext<SettingsStateModel>, { payload }: UpdateRecipeCategory) {
    return this.#settingsService
      .updateRecipeCategory(payload)
      .pipe(switchMap(() => this.getSettings(ctx, { payload: { retailerId: payload.retailer.id } })));
  }

  @Action(RemoveRecipeCategory)
  removeRecipeCategory(ctx: StateContext<SettingsStateModel>, { payload }: RemoveRecipeCategory) {
    return this.#settingsService
      .deleteRecipeCategory(payload.id)
      .pipe(switchMap(() => this.getSettings(ctx, { payload: { retailerId: payload.retailer.id } })));
  }

  @Action(AddAccountingCategory)
  addAccountingCategory(
    { patchState, getState }: StateContext<SettingsStateModel>,
    { payload }: AddAccountingCategory,
  ) {
    return this.#settingsService.createAccountingCategory(payload).pipe(
      tap(response =>
        patchState({
          accountingCategories: getState().accountingCategories.concat(AccountingCategory.deserialize(response)),
        }),
      ),
    );
  }

  @Action(UpdateAccountingCategory)
  updateAccountingCategory(_: StateContext<SettingsStateModel>, { payload }: UpdateRecipeCategory) {
    return this.#settingsService.updateAccountingCategory(payload);
  }

  @Action(RemoveAccountingCategory)
  removeAccountingCategory({ setState }: StateContext<SettingsStateModel>, { payload }: RemoveRecipeCategory) {
    return this.#settingsService.deleteAccountingCategory(payload.id).pipe(
      tap(() => {
        setState(
          patch({
            accountingCategories: removeItem<AccountingCategory>(
              accountingCategory => accountingCategory?.id === payload.id,
            ),
          }),
        );
      }),
    );
  }

  @Action(CreateSalesType)
  createSalesType(ctx: StateContext<SalesTypeStateModel>, action: CreateSalesType) {
    const state = ctx.getState();

    return this.#settingsService.createSalesType(action.retailerId, action.payload).pipe(
      tap((createdSalesType: SalesType) => {
        const updatedSalesTypes = [...state.salesTypes, createdSalesType];

        ctx.patchState({ salesTypes: updatedSalesTypes });
      }),
    );
  }

  @Action(UpdateSalesType)
  updateSalesType(ctx: StateContext<SalesTypeStateModel>, action: UpdateSalesType) {
    const state = ctx.getState();
    const updatedSalesTypes = state.salesTypes.map(salesType =>
      salesType.id === action.payload.id ? { ...salesType, ...action.payload } : salesType,
    );

    ctx.patchState({ salesTypes: updatedSalesTypes });

    const salesType = {
      name: action.payload.name,
      code: action.payload.code,
    };

    return this.#settingsService.updateSalesType(action.retailerId, salesType, action.payload.id);
  }

  @Action(DeleteSalesType)
  deleteSalesType(ctx: StateContext<SalesTypeStateModel>, action: DeleteSalesType) {
    return this.#settingsService.deleteSalesType(action.retailerId, action.payload.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const filteredSalesTypes = state.salesTypes.filter(salesType => salesType.id !== action.payload.id);

        ctx.patchState({ salesTypes: filteredSalesTypes });
      }),
    );
  }

  @Action(MakeDefaultSalesType)
  makeDefaultSalesType(ctx: StateContext<SalesTypeStateModel>, action: MakeDefaultSalesType) {
    const state = ctx.getState();
    const updatedSalesTypes = state.salesTypes.map(salesType => ({
      ...salesType,
      isDefault: salesType.id === action.payload.id,
    }));

    ctx.patchState({ salesTypes: updatedSalesTypes });

    return this.#settingsService.makeDefaultSalesType(action.retailerId, action.payload.id);
  }
}
