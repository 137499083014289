<supy-dialog
  class="supy-user-settings"
  initialState="open"
  width="40vw"
  position="right"
  stretch="vertical"
  [closeOnEscape]="true"
  [closeOnOutsideClick]="true"
  [overlayClass]="overlayClass"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="dialogOpened.emit($event)"
  (dialogOpening)="dialogOpening.emit($event)"
  (dialogClosing)="dialogClosing.emit($event)"
>
  <supy-dialog-header>
    <div class="supy-user-settings__header supy-user-settings__flex-grow">
      <div class="supy-user-settings__title">
        <supy-button type="icon" (buttonClick)="dialog.closeDialog()">
          <supy-icon name="close-circle"></supy-icon>
        </supy-button>
        <h5 i18n="@@preferences">Preferences</h5>
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content>
    <form class="supy-user-settings" [formGroup]="form">
      <div class="supy-user-settings__section">
        <div class="supy-user-settings__section-input">
          <span class="supy-user-settings__section-label" i18n="@@settings.general.language">Language</span>
          <supy-language-select name="preferredLanguage" formControlName="preferredLanguage"></supy-language-select>
        </div>
        <div class="supy-user-settings__section-input">
          <supy-checkbox name="sendNewOrderEmail" formControlName="sendNewOrderEmail"
            >Receive order emails text</supy-checkbox
          >
        </div>
      </div>
    </form>
  </supy-dialog-content>
  <supy-dialog-footer>
    <div class="supy-user-settings__buttons">
      <supy-button (buttonClick)="dialog.closeDialog()" color="default-outline" name="cancel" i18n="@@cancel"
        >Cancel</supy-button
      >
      <supy-button name="save" (buttonClick)="onSave()" color="primary" [disabled]="isLoading()" i18n="@@save"
        >Save</supy-button
      >
    </div>
  </supy-dialog-footer>
</supy-dialog>
